<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item style="cursor: pointer">
          <span @click="onBack">
            商品管理
          </span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>{{ breadcrumbTitle }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="content-container">
      <a-row class="form-container">
        <div class="content-main">
          <a-form :form="form" class="advanced-search-form">
            <a-row>
              <a-col :span="8">
                <a-form-item label="商品类型">
                  {{ productType }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="商品编码">
                  <a-input
                    v-decorator="[
                      'productCode',
                      { rules: [{ required: true }] }
                    ]"
                    placeholder="请输入商品编码"
                    :disabled="disabled"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="商品名称">
                  <a-input
                    v-decorator="[
                      'productName',
                      { rules: [{ required: true }] }
                    ]"
                    placeholder="请输入商品名称"
                    :disabled="disabled"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="8">
                <a-form-item label="商品体积">
                  <div class="input-container">
                    <a-input
                      v-decorator="[
                        'productVolume',
                        { rules: [{ required: true }] }
                      ]"
                      placeholder="请输入商品体积"
                      :disabled="disabled"
                    />
                    <div>m³</div>
                  </div>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="装运体积">
                  <div class="input-container">
                    <a-input
                      v-decorator="[
                        'packVolume',
                        { rules: [{ required: true }] }
                      ]"
                      placeholder="请输入装运体积"
                      :disabled="disabled"
                    />
                    <div>m³</div>
                  </div>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="商品重量">
                  <div class="input-container">
                    <a-input
                      v-decorator="[
                        'productWeight',
                        { rules: [{ required: true }] }
                      ]"
                      placeholder="请输入装运体积"
                      :disabled="disabled"
                    />
                    <div>m³</div>
                  </div>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="8">
                <a-form-item v-if="productType == '整车'" label="车系列">
                  <a-input
                    v-decorator="['series', { rules: [{ required: true }] }]"
                    placeholder="请输入车系列"
                    :disabled="disabled"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item v-if="productType == '整车'" label="车颜色">
                  <a-input
                    v-decorator="['color', { rules: [{ required: true }] }]"
                    placeholder="请输入车颜色"
                    :disabled="disabled"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item v-if="productType == '整车'" label="车配置">
                  <a-input
                    v-decorator="[
                      'specification',
                      { rules: [{ required: true }] }
                    ]"
                    placeholder="请输入车配置"
                    :disabled="disabled"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item v-if="productType == '配件'" label="配件部位">
                  <a-input
                    v-decorator="['position', { rules: [{ required: true }] }]"
                    placeholder="请输入配件部位"
                    :disabled="disabled"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item v-if="productType == '配件'" label="配件功用">
                  <a-input
                    v-decorator="[
                      'functionality',
                      { rules: [{ required: true }] }
                    ]"
                    placeholder="请输入配件功用"
                    :disabled="disabled"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="8">
                <a-form-item label="商品介绍">
                  <a-textarea
                    v-decorator="[
                      'productDescription',
                      { rules: [{ required: true }] }
                    ]"
                    placeholder="请输入商品介绍"
                    :disabled="disabled"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-form-item label="商品图片">
              <a-upload
                :show-upload-list="{ showRemoveIcon: !disabled }"
                :action="imgUrl"
                list-type="picture-card"
                accept=".png,.jpg,.jpeg,.gif,.svg"
                :file-list="productImages"
                :before-upload="file => beforeUploadFile(file, 'productImages')"
                @preview="previewCommodityImg"
              >
                <div v-if="productImages.length < 1 && disabled === false">
                  <a-icon type="plus" />
                </div>
              </a-upload>
              <a-modal
                :visible="productImagesVisible"
                :footer="null"
                @cancel="productImagesVisible = false"
              >
                <img
                  alt="example"
                  style="width: 100%"
                  :src="previewProductImage"
                />
              </a-modal>
            </a-form-item>
            <a-form-item label="展示图">
              <a-upload
                :show-upload-list="{ showRemoveIcon: !disabled }"
                :action="imgUrl"
                list-type="picture-card"
                accept=".png,.jpg,.jpeg,.gif,.svg"
                :file-list="exhibitionImages"
                :before-upload="
                  file => beforeUploadFile(file, 'exhibitionImages')
                "
                @preview="previewDisplayImg"
              >
                <div v-if="exhibitionImages.length < 8 && disabled === false">
                  <a-icon type="plus" />
                </div>
              </a-upload>
              <a-modal
                :visible="exhibitionImagesVisible"
                :footer="null"
                @cancel="exhibitionImagesVisible = false"
              >
                <img
                  alt="example"
                  style="width: 100%"
                  :src="previewExhibitionImage"
                />
              </a-modal>
            </a-form-item>
            <a-form-item label="其他展示照片或者渲染图">
              <a-upload
                :show-upload-list="{ showRemoveIcon: !disabled }"
                :action="imgUrl"
                list-type="picture-card"
                accept=".png,.jpg,.jpeg,.gif,.svg"
                :file-list="otherImages"
                :before-upload="file => beforeUploadFile(file, 'otherImages')"
                @preview="previewOtherImg"
              >
                <div v-if="otherImages.length < 8 && disabled === false">
                  <a-icon type="plus" />
                </div>
              </a-upload>
              <a-modal
                :visible="otherImagesVisible"
                :footer="null"
                @cancel="otherImagesVisible = false"
              >
                <img
                  alt="example"
                  style="width: 100%"
                  :src="previewOtherImage"
                />
              </a-modal>
            </a-form-item>
          </a-form>
        </div>
      </a-row>
    </div>
    <div class="examine-container">
      <div class="button-container">
        <a-button type="white" class="button-item" @click="onEdit">
          {{ btnTitle }}
        </a-button>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import { commodityDetail } from "../../services/CommodityService";
import { imgUrl, upload } from "../../services/UploadService";
import { genDownloadUrl, imageBaseUrl } from "../../services/HttpService";

// function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = error => reject(error);
//   });
// }

export default {
  data() {
    return {
      imgUrl,
      genDownloadUrl,
      breadcrumbTitle: "商品详情",
      form: this.$form.createForm(this),
      productType: "",
      disabled: true,
      btnTitle: "修改",
      productImages: [],
      exhibitionImages: [],
      otherImages: [],
      productId: this.$route.params.productId,
      productImagesVisible: false,
      previewProductImage: "",
      exhibitionImagesVisible: false,
      previewExhibitionImage: "",
      otherImagesVisible: false,
      previewOtherImage: ""
    };
  },
  mounted() {
    this.onDetail();
  },

  methods: {
    onBack() {
      this.$router.push({ name: "CommodityManage" });
    },
    // 修改
    onEdit() {
      this.disabled = !this.disabled;
      if (this.disabled) {
        this.btnTitle = "修改";
      } else {
        this.btnTitle = "完成";
      }
    },
    // 查询详情
    onDetail() {
      commodityDetail(this.productId).then(resp => {
        if (resp.data.code === "FAILED") {
          this.$message.error(resp.data.errorMsg);
        } else {
          const data = resp.data.data;
          this.productType = data.productType;
          this.form.setFieldsValue({ productCode: data.productCode });
          this.form.setFieldsValue({ productName: data.productName });
          this.form.setFieldsValue({ productVolume: data.productVolume });
          this.form.setFieldsValue({ packVolume: data.packVolume });
          this.form.setFieldsValue({ productWeight: data.productWeight });
          this.form.setFieldsValue({
            productDescription: data.productDescription
          });
          if (this.productType === "整车") {
            setTimeout(() => {
              this.form.setFieldsValue({ series: data.series });
              this.form.setFieldsValue({ color: data.color });
              this.form.setFieldsValue({ specification: data.specification });
            }, 500);
          } else if (this.productType === "配件") {
            setTimeout(() => {
              this.form.setFieldsValue({ position: data.position });
              this.form.setFieldsValue({ functionality: data.functionality });
            }, 500);
          }
          const productImages = data.productImages;
          if (productImages.length > 0) {
            this.productImages = [];
            productImages.forEach((item, index) => {
              const param = {
                uid: index,
                name: item.fileName,
                status: "done",
                url: genDownloadUrl(item.fileUrl)
              };
              this.productImages.push(param);
            });
          }
          const exhibitionImages = data.exhibitionImages;
          if (exhibitionImages.length > 0) {
            this.exhibitionImages = [];
            exhibitionImages.forEach((item, index) => {
              const param = {
                uid: index,
                name: item.fileName,
                status: "done",
                url: genDownloadUrl(item.fileUrl)
              };
              this.exhibitionImages.push(param);
            });
          }
          const otherImages = data.otherImages;
          if (otherImages.length > 0) {
            this.otherImages = [];
            otherImages.forEach((item, index) => {
              const param = {
                uid: index,
                name: item.fileName,
                status: "done",
                url: genDownloadUrl(item.fileUrl)
              };
              this.otherImages.push(param);
            });
          }
        }
      });
    },
    previewCommodityImg(file) {
      if (file.url) {
        this.previewProductImage = file.url;
        this.productImagesVisible = true;
      }
    },
    previewDisplayImg(file) {
      if (file.url) {
        this.previewExhibitionImage = file.url;
        this.exhibitionImagesVisible = true;
      }
    },
    previewOtherImg(file) {
      if (file.url) {
        this.previewOtherImage = file.url;
        this.otherImagesVisible = true;
      }
    },
    beforeUploadFile(file, fileListName) {
      const size = file.size;
      const fileName = JSON.stringify(file.name);
      if (fileName.length > 100) {
        this.$message.error("文件名称的长度需在100个字以内");
      } else {
        if (size > 20 * 1024 * 1024) {
          this.$message.error("文件大小超过20MB");
        } else {
          this.uploadFile("product_picture", file, fileListName);
        }
      }
      return false;
    },
    async uploadFile(type, file, fileListName) {
      const { data = {} } = await upload(type, file, "other");
      if (data.code === "FAILED") {
        this.$message.error(data.errorMsg);
      } else {
        let list = [];
        list.push({
          uid: list.length,
          name: file.name,
          fileName: file.name,
          url: imageBaseUrl + data.data,
          fileUrl: data.data,
          response: data
        });
        this[fileListName] = [...list];
      }
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.content-main {
  width: 100%;
}
.input-container {
  display: flex;
}
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
.button-container {
  width: 12%;
  display: flex;
  justify-content: space-around;
}
/deep/ .ant-form-item .ant-form-item-label {
  min-width: 168px !important;
}
</style>
